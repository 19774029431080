import React, { useEffect, useState } from 'react';
import axios from 'axios';

const RequestsToSeller = () => {
    const [requests, setRequests] = useState([]);

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const response = await axios.get('https://backend.alamkitab.com/api/book-requests');
                setRequests(response.data);
            } catch (error) {
                console.error('Error fetching book requests', error);
            }
        };

        fetchRequests();
    }, []);

    return (
        <div>
            <h2>Customer Book Requests</h2><br />
            <div style={{ overflowX: 'auto', maxWidth: '100%' }}> {/* Enables horizontal scrolling */}
                <table style={{ minWidth: '800px', borderCollapse: 'collapse' }}> {/* Set a minimum width for the table */}
                    <thead>
                        <tr>
                            {/* <th style={{ border: '1px solid #ccc', padding: '8px' }}>Reason</th> */}
                            <th style={{ border: '1px solid #ccc', padding: '8px' }}>Book Name</th>
                            <th style={{ border: '1px solid #ccc', padding: '8px' }}>Name</th>
                            <th style={{ border: '1px solid #ccc', padding: '8px' }}>Email</th>
                            <th style={{ border: '1px solid #ccc', padding: '8px' }}>Phone</th>
                            <th style={{ border: '1px solid #ccc', padding: '8px' }}>Customer Message or Queries / Book Description</th>
                            <th style={{ border: '1px solid #ccc', padding: '8px' }}>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {requests.map((request) => (
                            <tr key={request._id}>
                                {/* <td style={{ border: '1px solid #ccc', padding: '8px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {request.reason}
                                </td> */}
                                <td style={{ border: '1px solid #ccc', padding: '8px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {request.description}
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {request.name}
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {request.email}
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {request.phone}
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {request.message}
                                </td>
                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                    {new Date(request.createdAt).toLocaleString()}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RequestsToSeller;
