import React from 'react';
import './footer.css';
import 'boxicons';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <footer className="footer-distributed footer">
        <div className="footer-left">
          <h3>
            <span style={{ color: '#28a745' }}>Alam</span>kitab
          </h3>
          <p className="footer-company-name">
            Copyright © 2024 <strong>Alamkitab</strong>. All rights reserved.
          </p><br /><br /><br />
          <p className="footer-credit footer-company-name">
            Website developed by{' '}
            <strong>
              <Link
                to="https://wahaj-portfolio.web.app/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#f39c12', textDecoration: 'none' }}
              >
                Wahaj
              </Link>
            </strong>
          </p>
        </div>

        <div className="footer-center">
          <div className="footer-icons">
            <i className="fa fa-map-marker"></i>
            <p>
              <span>Karachi,</span> Pakistan
            </p>
          </div>

          <div className="footer-icons">
            <i className="fa fa-phone"></i>
            <p>
              <a href="https://wa.me/+923322440544" target="_blank" rel="noopener noreferrer">
                +92 332 2440544
              </a>
            </p>
          </div>

          <div className="footer-icons">
            <i className="fa fa-envelope"></i>
            <p>
              <a href="mailto:contactus@alamkitab.com">contactus@alamkitab.com</a>
            </p>
          </div>
        </div>

        <div className="footer-right">
          <p className="footer-company-about">
            <strong>Welcome to Alamkitab,</strong> Discover a one-stop online bookstore delivering across Pakistan! Explore a wide range of books and enjoy convenient online printing services for all your personal and business needs. Fast delivery, easy ordering, and reliable service!
          </p>
          <div className="social-icons">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-tiktok"></i>
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
