import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    TextField, MenuItem, Button, Grid, Select, Typography,
    FormControl, InputLabel, Card, CardContent, CardActions, Dialog,
    DialogTitle, DialogContent, DialogActions,
    Box
} from '@mui/material';
import { Email, Phone } from '@mui/icons-material';

const PrintRequestForm = () => {
    const [formData, setFormData] = useState({
        paperQuality: '',
        color: '',
        numPages: 1,
        quantity: 1,
        email: '',
        shippingAddress: '',
        city: '',
        name: '',
        phone: '',
        deliverySpeed: '',
        totalCost: 0,
        promoCode: '',
        instructions: '', // Added custom instructions to form data
    });

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errors, setErrors] = useState({ email: '', phone: '' });
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [showTotal, setShowTotal] = useState(false);
    const [promoError, setPromoError] = useState('');

    const validPromoCodes40 = ['BWAY567', 'BWAA567', 'KHANALAM', 'FA40'];
    const validPromoCodes20 = ['WA20', 'AK20','NOV20'];

    useEffect(() => {
        const savedMessage = localStorage.getItem('successMessage');
        if (savedMessage) setSuccessMessage(savedMessage);
    }, []);

    useEffect(() => {
        const costPerPage = formData.color === 'Color' ? 40 : 20;
        const printingCost = formData.numPages * formData.quantity * costPerPage;
        const deliveryCost =
            formData.deliverySpeed === 'Urgent'
                ? 500
                : formData.deliverySpeed === 'Normal'
                ? 250
                : 120;

        let total = printingCost + deliveryCost;

        if (validPromoCodes40.includes(formData.promoCode)) {
            total *= 0.6; // 40% discount
            setPromoError('');
        } else if (validPromoCodes20.includes(formData.promoCode)) {
            total *= 0.8; // 20% discount
            setPromoError('');
        } else if (formData.promoCode) {
            setPromoError('Invalid promo code');
        }

        setFormData((prevData) => ({
            ...prevData,
            totalCost: total,
        }));

        if (formData.color || formData.deliverySpeed) {
            setShowTotal(true);
        } else {
            setShowTotal(false);
        }
    }, [formData.numPages, formData.quantity, formData.color, formData.deliverySpeed, formData.promoCode]);

    const validateEmail = (email) => {
        if (!email.includes('@')) {
            setErrors((prev) => ({ ...prev, email: 'Email must contain @' }));
        } else {
            setErrors((prev) => ({ ...prev, email: '' }));
        }
    };

    const validatePhone = (phone) => {
        if (phone.length !== 11 || !/^\d+$/.test(phone)) {
            setErrors((prev) => ({ ...prev, phone: 'Phone number must be 11 digits' }));
        } else {
            setErrors((prev) => ({ ...prev, phone: '' }));
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'email') validateEmail(value);
        if (name === 'phone') validatePhone(value);
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
        }
    };

    const handleSubmit = async () => {
        if (errors.email || errors.phone) {
            alert('Please fix the errors before submitting.');
            return;
        }

        const data = new FormData();
        Object.keys(formData).forEach((key) => data.append(key, formData[key]));
        if (file) data.append('uploadedFile', file);

        try {
            const response = await axios.post('https://backend.alamkitab.com/api/print-request', data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            const message = `✅ Order received! Your order number is ${response.data.request.pdfNumber}. Total amount: ${formData.totalCost} Rs [Cash on delivery] For any queries, feel free to WhatsApp us at 0332-2440544`;
            setSuccessMessage(message);
            localStorage.setItem('successMessage', message);
            setOpenSuccessDialog(true);

            setFormData({
                paperQuality: '',
                color: '',
                numPages: 1,
                quantity: 1,
                email: '',
                shippingAddress: '',
                city: '',
                name: '',
                phone: '',
                deliverySpeed: '',
                totalCost: 0,
                promoCode: '',
                instructions: '', // Reset custom instructions
            });
            setFile(null);
            setFileName('');
            setShowTotal(false);
        } catch (error) {
            alert('Please upload your document again.');
        }
    };

    const handlePlaceOrder = () => {
        if (errors.email || errors.phone) {
            alert('Please fix the errors before submitting.');
            return;
        }

        setOpenConfirmationDialog(true);
    };

    const handleConfirmationClose = (isConfirmed) => {
        setOpenConfirmationDialog(false);

        if (isConfirmed) {
            handleSubmit();
        }
    };

    const handleNewRequest = () => {
        const confirmNewRequest = window.confirm(
            'Are you sure you want to start a new request? All unsaved data will be lost.'
        );
        if (!confirmNewRequest) return;

        localStorage.removeItem('successMessage');
        setSuccessMessage('');
    };

    const handleCloseSuccessDialog = () => {
        setOpenSuccessDialog(false);
    };

    return (
        <Card
            style={{
                maxWidth: 700,
                margin: 'auto',
                padding: '30px',
                marginTop: '50px',
                boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
            }}
        >
            <CardContent>
                <Typography variant="h6" align="center" gutterBottom style={{ color: '#104', fontWeight: 'bolder' }}>
                    Please submit your order along with the printing details
                </Typography>
                <form onSubmit={(e) => e.preventDefault()} encType="multipart/form-data">
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <FormControl fullWidth required>
                                <InputLabel>Paper Size</InputLabel>
                                <Select
                                    name="paperQuality"
                                    value={formData.paperQuality}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="A4">A4</MenuItem>
                                    <MenuItem value="A5">A5</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth required>
                                <InputLabel>Color Type</InputLabel>
                                <Select
                                    name="color"
                                    value={formData.color}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="Black & White">Black & White (Rs: 20)</MenuItem>
                                    <MenuItem value="Color">Color (Rs: 40)</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                name="numPages"
                                label="Number of Pages"
                                type="number"
                                fullWidth
                                required
                                value={formData.numPages}
                                onChange={handleInputChange}
                                inputProps={{ min: 1 }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                name="quantity"
                                label="No of Copies"
                                type="number"
                                fullWidth
                                required
                                value={formData.quantity}
                                onChange={handleInputChange}
                                inputProps={{ min: 1 }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                name="name"
                                label="Your Name"
                                fullWidth
                                required
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                name="email"
                                label="Email"
                                fullWidth
                                required
                                value={formData.email}
                                error={!!errors.email}
                                helperText={errors.email}
                                onChange={handleInputChange}
                                InputProps={{ startAdornment: <Email position="start" /> }}
                            />
                        </Grid>


                        <Grid item xs={12}>
                            <TextField
                                name="shippingAddress"
                                label="Shipping Address"
                                fullWidth
                                required
                                value={formData.shippingAddress}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="phone"
                                label="Phone Number"
                                fullWidth
                                required
                                value={formData.phone}
                                error={!!errors.phone}
                                helperText={errors.phone}
                                onChange={handleInputChange}
                                InputProps={{ startAdornment: <Phone position="start" /> }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth required>
                                <InputLabel>City</InputLabel>
                                <Select
                                    name="city"
                                    value={formData.city}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="Karachi">Karachi</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        

                        <Grid item xs={6}>
                            <TextField
                                name="deliverySpeed"
                                label="Delivery Speed"
                                select
                                fullWidth
                                required
                                value={formData.deliverySpeed}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="Urgent">Urgent (1 day) (Rs: 500)</MenuItem>
                                <MenuItem value="Normal">Normal (2-4 days) (Rs: 250)</MenuItem>
                                <MenuItem value="Economical">Economical (5-8 days) (Rs: 120)</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                name="promoCode"
                                label="Promo Code (Optional)"
                                fullWidth
                                value={formData.promoCode}
                                onChange={handleInputChange}
                                error={!!promoError}
                                helperText={promoError}
                            />
                        </Grid>


                        <Grid item xs={12}>
                            <TextField
                                name="instructions"
                                label="Instructions"
                                fullWidth
                                multiline
                                rows={4}
                                value={formData.instructions} // New field for custom instructions
                                onChange={handleInputChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                        Supported file format: PDF, MS Word
                            <Button
                                variant="outlined"
                                component="label"
                                fullWidth
                                style={{
                                    padding: '10px',
                                    border: '2px dashed #b3b3b3',
                                    borderRadius: '10px',
                                    color: '#000',
                                }}
                            >
                                {fileName || 'Click here to upload your document'}
                                <input type="file" hidden onChange={handleFileChange} />
                            </Button>
                        </Grid>

                        {/* Display total cost */}
                        <Grid item xs={12}>
                            {showTotal && (
                                <Typography variant="h6" align="center" style={{color: '#006001', fontWeight:'bolder'}}>
                                    Total Amount (Cost + Delivery Charges): Rs {formData.totalCost}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </form>
            </CardContent>

            <CardActions>
                <Button
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: '#006001'}}
                    onClick={handlePlaceOrder}
                >
                    Place Order
                </Button>
            </CardActions>

            {/* Confirmation Dialog */}
            <Dialog open={openConfirmationDialog} onClose={() => setOpenConfirmationDialog(false)}>
                <DialogTitle>Confirm Order</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to place this order?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConfirmationClose(false)} style={{ backgroundColor: '#006001', color: '#fff'}}>Cancel</Button>
                    <Button onClick={() => handleConfirmationClose(true)} style={{ backgroundColor: '#000600', color: '#fff'}}>Confirm</Button>
                </DialogActions>
            </Dialog>

            {/* Success Dialog */}
            <Dialog open={openSuccessDialog} onClose={handleCloseSuccessDialog}>
                <DialogTitle>Order Success</DialogTitle>
                <DialogContent>
                    <Typography>{successMessage}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSuccessDialog} color="primary">Close</Button>
                    {/* <Button onClick={handleNewRequest} color="secondary">New Request</Button> */}
                </DialogActions>
            </Dialog>

            <Box sx={{ textAlign: 'center', marginTop: '16px' }}>
                <Typography
                    sx={{
                        color: 'green',
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                        padding: '8px',
                        border: '1px solid green',
                        borderRadius: '4px',
                        backgroundColor: '#e0f7e0', 
                        display: 'inline-block', 
                        margin: '0 auto', // Centers the block horizontally
                    }}
                >
                    {successMessage}
                </Typography>
            </Box>
        </Card>
    );
};

export default PrintRequestForm;
