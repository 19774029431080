import { Grid } from '@mui/material';
import SalesCard from '../components/SalesCard';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const SellerHomePage = () => {
  const { specificProductData } = useSelector((state) => state.user);

  const [monthlySales, setMonthlySales] = useState(0);
  const [dailySales, setDailySales] = useState([]);
  const [yearlySales, setYearlySales] = useState([]); // Current year (2024) sales data
  const [futureSales, setFutureSales] = useState([]); // Future years (2024, 2025, 2026...) sales data
  const [ongoingOrders, setOngoingOrders] = useState(0);
  const [completedOrders, setCompletedOrders] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const isThisYear = (date) => {
    const now = new Date();
    const orderDate = new Date(date);
    return orderDate.getFullYear() === now.getFullYear();
  };

  const getMonthName = (date) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[new Date(date).getMonth()];
  };

  useEffect(() => {
    if (Array.isArray(specificProductData) && specificProductData.length > 0) {
      const dailySalesData = Array.from({ length: 31 }, (_, index) => ({
        day: index + 1, 
        sales: 0,
      }));

      const yearlySalesData = Array.from({ length: 12 }, (_, index) => ({
        month: getMonthName(new Date(2024, index, 1)), // Get month name for each month in 2024
        sales: 0,
      }));

      // Future sales data for years 2024 and beyond (adjust this range as needed)
      const futureSalesData = Array.from({ length: 5 }, (_, index) => ({
        year: 2024 + index, // Start from 2024
        sales: 0,
      }));

      specificProductData.forEach((order) => {
        // Process daily sales
        if (order.paidAt) {
          const orderDate = new Date(order.paidAt);
          const now = new Date();
          
          // Monthly sales for 2024
          if (isThisYear(order.paidAt)) {
            const month = orderDate.getMonth(); // Get the month index (0-11)
            yearlySalesData[month].sales += 1; // Increment sales for the specific month
          }

          // Daily sales for the current month
          if (orderDate.getMonth() === now.getMonth() && orderDate.getFullYear() === now.getFullYear()) {
            const day = orderDate.getDate(); // Get the day of the month (1-31)
            dailySalesData[day - 1].sales += 1; // Increment sales for the specific day
          }

          // Future sales data for 2024 onwards
          const orderYear = orderDate.getFullYear();
          const yearIndex = futureSalesData.findIndex((yearData) => yearData.year === orderYear);
          if (yearIndex !== -1) {
            futureSalesData[yearIndex].sales += 1; // Increment sales for the specific year
          }
        }
      });

      // Calculate total monthly sales
      const thisMonthOrders = dailySalesData.reduce((total, dayData) => total + dayData.sales, 0);

      // Count ongoing and completed orders
      const ongoing = specificProductData.filter(order =>
        ['Processing', 'Packed', 'Shipped'].includes(order.orderStatus)
      ).length;

      const completed = specificProductData.filter(order =>
        order.orderStatus === 'Delivered'
      ).length;

      // Set state
      setMonthlySales(thisMonthOrders);
      setOngoingOrders(ongoing);
      setCompletedOrders(completed);
      setDailySales(dailySalesData); // Set daily sales for the month
      setYearlySales(yearlySalesData); // Set yearly sales for 2024
      setFutureSales(futureSalesData); // Set future sales data
      setIsLoading(false);
    } else {
      setIsLoading(false); // Even if no data, stop loading
    }
  }, [specificProductData]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Grid container spacing={3} sx={{ padding: "9px" }}>
      <Grid item xs={12} sm={6} md={3}>
        <SalesCard title="Monthly Sales" total={monthlySales} color='primary' icon={'ant-design:carry-out-filled'} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <SalesCard title="Ongoing Orders" total={ongoingOrders} color="warning" icon={'material-symbols:data-exploration'} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <SalesCard title="Completed Orders" total={completedOrders} color="info" icon={'material-symbols:done-all'} />
      </Grid>

      <Grid item xs={12}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={dailySales} // Daily sales for the current month
            margin={{
              top: 20, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" />
            <YAxis tickFormatter={(value) => Number.isInteger(value) ? value : ''} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="sales" stroke="#82ca9d" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </Grid>

      <Grid item xs={12}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={yearlySales} // Yearly sales for 2024
            margin={{
              top: 20, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis tickFormatter={(value) => Number.isInteger(value) ? value : ''} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="sales" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </Grid>

      <Grid item xs={12}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={futureSales} // Future sales from 2024 onwards
            margin={{
              top: 20, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis tickFormatter={(value) => Number.isInteger(value) ? value : ''} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="sales" stroke="#ffc658" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

export default SellerHomePage;
