import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Paper, Typography, CircularProgress, Box } from '@mui/material';

const SellerPage = () => {
    const [printRequests, setPrintRequests] = useState([]);
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        const fetchPrintRequests = async () => {
            try {
                const response = await axios.get('https://backend.alamkitab.com/api/print-requests');
                console.log('Fetched print requests:', response.data); // Debug log
                setPrintRequests(response.data);
            } catch (error) {
                console.error('Error fetching print requests:', error);
                alert('Failed to fetch print requests: ' + (error.response ? error.response.data.message : error.message));
            } finally {
                setLoading(false); // Hide loader once data is fetched
            }
        };

        fetchPrintRequests();
    }, []);

    return (
        <div>
            <Typography variant="h4">Customer Print Requests</Typography>

            {/* Show loader when loading */}
            {loading ? (
                <Box 
                    display="flex" 
                    justifyContent="center" 
                    alignItems="center" 
                    height="80vh"
                >
                    <CircularProgress size={60} style={{ color: '#3f51b5' }} />
                </Box>
            ) : (
                <Grid container spacing={2}>
                    {printRequests.map((request) => (
                        <Grid item xs={12} sm={6} key={request._id}>
                            <Paper style={{ padding: '16px', margin: '16px' }}>
                                {/* Customer Information */}
                                <Typography variant="body1"><strong>Name:</strong> {request.name}</Typography>
                                <Typography variant="body1">
                                    <strong>Email:</strong> {request.email}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Phone Number:</strong> {request.phone}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Shipping Address:</strong> {request.shippingAddress}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>City:</strong> {request.city} {/* Added city field */}
                                </Typography>

                                {/* Print Request Information */}
                                <Typography variant="body1">
                                    <strong>Paper Size:</strong> {request.paperQuality}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Color Type:</strong> {request.color}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>PDF Number:</strong> {request.pdfNumber}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Pages:</strong> {request.numPages}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Quantity:</strong> {request.quantity}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Delivery Type:</strong> {request.deliverySpeed} {/* Display delivery type */}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Order Time:</strong> {new Date(request.createdAt).toLocaleString()} {/* Display order time */}
                                </Typography>

                                {/* Promo Code Information */}
                                <Typography variant="body1">
                                    <strong>Promo Code:</strong> {request.promoCode || 'None'} {/* Added promo code */}
                                </Typography>

                                <Typography variant="body1">
                                    <strong>Customer Instruction:</strong> {request.instructions || 'None'} {/* Added promo code */}
                                </Typography>

                                {/* Cost Information */}
                                <Typography variant="body1">
                                    <strong>Total Cost (Including Delivery):</strong> Rs {request.totalCost}
                                </Typography>

                                {/* Uploaded File Link */}
                                {request.uploadedFile && (
                                    <a href={`https://backend.alamkitab.com/uploads/${request.uploadedFile}`} target="_blank" rel="noopener noreferrer">
                                        View Uploaded File
                                    </a>
                                )}
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            )}
        </div>
    );
};

export default SellerPage;
