import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import TableTemplate from "../../../components/TableTemplate";
import { useNavigate } from "react-router-dom";
import { BlueButton } from "../../../utils/buttonStyles";

const CustomerListSection = () => {
    const navigate = useNavigate();

    // State to hold customer data fetched from /getAllCustomers
    const [customers, setCustomers] = useState([]);

    // Fetch all customers when component mounts
    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await fetch('https://backend.alamkitab.com/getAllCustomers');
                if (!response.ok) throw new Error('Failed to fetch customers');
    
                const data = await response.json();
                // console.log('Fetched customers:', data);  // Log customer data for debugging
                setCustomers(data); // Store customers in the state
            } catch (error) {
                console.error('Error fetching customers:', error);
            }
        };
    
        fetchCustomers();
    }, []);

    // Define columns for the customers table
    const customersColumns = [
        { id: 'customerName', label: 'Customer Name', minWidth: 150 },
        { id: 'customerEmail', label: 'Customer Email', minWidth: 200 },
        { id: 'customerID', label: 'Customer ID', minWidth: 150 }
    ];

    // Map customers data to table rows
    const customersRows = Array.isArray(customers) && customers.length > 0
        ? customers.map((customer) => ({
            customerName: customer.customerName,
            customerEmail: customer.customerEmail,
            customerID: customer.customerID,
        }))
        : [];

    const CustomersButtonHaver = ({ row }) => {
        return (
            <>
                <BlueButton
                    onClick={() => {
                        navigate("/Seller/orders/customers/" + row.customerID); // Navigate to customer detail page
                    }}
                >
                    View Customer
                </BlueButton>
            </>
        );
    };

    return (
        <>
            <Typography variant="h5" gutterBottom>
                Customer List:
            </Typography>

            <TableTemplate buttonHaver={CustomersButtonHaver} columns={customersColumns} rows={customersRows} />
        </>
    );
};

export default CustomerListSection;
